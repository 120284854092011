import { ChainId } from '@abstra-dex/sdk'
import axios from 'axios'
import { BASE_API } from 'config/constants'
import { useCallback } from 'react'

export const useRecordExchangeCurrency = () => {
  return useCallback(
    async ({
      chainId,
      account,
      inputToken,
      outputToken,

      inputAmount,
      outputAmount,
      transactionHash,
      type,
    }: {
      type: 'swap' | 'add-liquidity'

      chainId: ChainId
      account: string
      inputToken: string
      outputToken: string

      inputAmount: number
      outputAmount: number
      transactionHash: string
    }) => {
      let url = ''
      let params = {}

      if (type === 'swap') {
        url = '/swap'
        params = {
          chainId,
          walletAddress: account,

          inputToken,
          outputToken,

          inputAmount,
          outputAmount,

          transactionHash,
        }
      } else if (type === 'add-liquidity') {
        url = '/liquidity'

        params = {
          chainId,
          walletAddress: account,

          tokenA: inputToken,
          tokenB: outputToken,

          amountA: inputAmount,
          amountB: outputAmount,
        }
      }

      const data = await axios.post(url, params, {
        baseURL: BASE_API,
      })
      return data
    },
    [],
  )
}
